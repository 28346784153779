<template>
  <div class="service-agreement">
    <h5 class="title">关于我们</h5>
    <div class="privacy-content">
<h1><strong>元宇未来科技（北京）有限公司</strong></h1>
<h1><strong>基本信息</strong></h1>
<p>公司名称：元宇未来科技（北京）有限公司</p>
<p>中文名：元宇未来</p>
<p>外文名：MetaIO</p>
<p>技术方向：元宇宙引擎</p>
<p>成立时间：&nbsp;2015年</p>
<p>企业法人：王宇</p>
<h1><strong>应用场景</strong></h1>
<p>营销、文旅、行业展会、教育培训、数字展厅、发布会、典礼等</p>
<h1><strong>核心产品</strong></h1>
<ol>
<li>MetaIO元宇宙引擎</li>
</ol>
<p>我们专注于元宇宙产业引擎的自主研发，基于web实现云端和本地混合渲染，媲美原生引擎，无需下载，无需代码，自动生成多端，具有底层生态完整服务能力。</p>
<p><img class="wscnph" src="http://img.hema.ink/images/abc4b2e3b0c8fb3195d0e346a5eeb896.jpeg" style="width: 100%;"/></p>
<h1><strong>产品价值</strong></h1>
<ol>
<li>易用：帮助没有建模及开发经验的工作者，通过丰富的模板及功能组件，0代码快速实现企业级个性化元宇宙场景搭建及互动功能的配置，一键上链</li>
<li>无下载：一键生成企业自有三端：小程序、网页、VR端</li>
<li>低成本：相较同样业务目标，成本降低约80%</li>
<li>可扩展：支持定制开发，满足多级别业务需求</li>
</ol>
<h1><strong>创始团队</strong></h1>
<p>团队成员毕业于自巴大、哈佛、哥大、MIT、北大，核心技术人员来自腾讯、阿里、华为技术团队，多年3d引擎开发经验</p>
<h1><strong>发展历史</strong></h1>
<p>2015年注册成立 多年专注于底层引擎开发</p>
<p>2022年3月推出0代码元宇宙引擎</p>
<p>2023年2月推出AI虚拟人直播解决方案</p>
<p>2023年9月预计推出MetaIO 2.0 AI元宇宙引擎</p>
<h1><strong>企业文化</strong></h1>
<p>愿景：改变互联网2.0，助力互联网3.0发展</p>
<p>使命：1.打造元宇宙生产器：降低构建及使用成本，降低入局门槛</p>
<p>2.形成元宇宙构建器：不断优化产品功能，完善行业服务标准</p>
<p>3.成为元宇宙路由器：打造元宇宙分布式生态效应</p>
<p>文化：work hard, dream big</p>
<p>&nbsp;</p>
<h1><strong>案例展示</strong></h1>
<p>2022年6月，参与设计制作&ldquo;北京大学新媒体研究院举办元宇宙毕业典礼&rdquo;</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "serviceAgreement",
  methods: {},
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
  ::v-deep #app{
    overflow-y: scroll;
  }
.service-agreement {
  color: #000;
  width: calc(100% - 400px);
  margin: 179px auto 151px;
  padding-bottom: 100px;
  overflow-y: scroll;
  .title {
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 59px;
    text-align: center;
  }
  .privacy-content {
    font-size: 34px;
    h5 {
      color: #000;
      font-weight: 700;
      margin: 20px 0;
      &.big {
        font-size: 48px;
      }
    }
    p {
      color: #606060;
      &.right {
        text-align: right;
        margin-top: 30px;
      }
      &:last-child {
        padding-bottom: 100px;
      }
    }
  }
}
</style>
